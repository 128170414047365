<template>
  <Particles
    id="tsparticles"
    :particlesLoaded="particlesLoaded"
    :options="particleOptions"
    style="
      height: 100%;
      width: 100%;
      position: absolute;
      overflow: hidden;
      z-index: 2;
      max-height: 100%;
    "
  />
</template>

<script>
export default {
  name: "SoParticles",

  data() {
    return {
      particlesInit: false,
      particlesLoaded: false,
      particleOptions: {
        fps_limit: 60,
        interactivity: {
          detect_on: "canvas",
          events: {
            ondiv: {
              enable: true,
              elementId: "login",
              mode: "bubble",
              type: "rectangle",
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 600,
              duration: 1,
              opacity: 0.9,
              size: 2,
              speed: 2,
              color: ["#ff0000", "#ff7700"],
            },
          },
        },
        particles: {
          color: {
            value: ["#00bfea", "#00255f"],
          },
          links: {
            color: "random",
            distance: 100,
            enable: true,
            opacity: 0.7,
            width: 1,
          },
          move: {
            collisions: false,
            direction: "none",
            enable: true,
            out_mode: "bounce",
            random: false,
            speed: 2,
            straight: false,
          },
          number: { density: { enable: true, value_area: 800 }, value: 80 },
          opacity: {
            animation: {
              enable: true,
              minimumValue: 0.3,
              speed: 1,
              sync: false,
            },
            random: true,
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            animation: {
              enable: true,
              minimumValue: 2,
              speed: 1,
              sync: true,
            },
            random: {
              enable: true,
              minimumValue: 2,
            },
            value: 4,
          },
        },
        retina_detect: true,
      },
    };
  },
  methods: {
    async particlesInitSo() {},
  },
};
</script>
